<template>
  <div>
    <b-modal
      id="create-capability-modal"
      title="Define Capability"
      size="xl"
      @show="show"
      @cancel="cancel"
    >
      <b-row>
        <!-- CAP/DESC/CUUR_STATE -->
        <b-col cols="6">
          <b-row class="mb-2">
            <b-col>
              <label for="txtCapabilityName">Capability</label>
              <b-form-textarea
                id="txtCapabilityName"
                v-model="localCapability.name"
                placeholder="Capability name..."
                rows="2"
              />
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col>
              <label for="txtCapabilityDetails">Detailed description</label>
              <b-form-textarea
                id="txtCapabilityDetails"
                v-model="localCapability.description"
                placeholder="Describe the capability..."
                rows="2"
              />
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col>
              <label for="txtCapabilityCurr">Current state</label>
              <b-form-textarea
                id="txtCapabilityCurr"
                v-model="localCapability.current"
                placeholder="Describe the current state/systems..."
                rows="3"
              />
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col>
              <label for="entityRich1">Current state gaps</label>
              <tip-tap-editor
                id="entityRich1"
                v-model="localCapability.gaps"
                placeholder="Describe the gaps in the current state/systems..."
              />
            </b-col>
          </b-row>
        </b-col> <!-- CAP/DESC/CUUR_STATE -->

        <!-- Future State Needs -->
        <b-col cols="6">
          <label for="fs">Future needs or improvements for state/system</label>
          <b-form-group>
            <div
              v-for="(need, index) in localCapability.needs"
              :id="need.id"
              :key="need.id"
              class="mb-1"
            >
              <b-row>
                <b-col cols="11">
                  <b-form-textarea
                    id="txtNeedDetails"
                    v-model="need.description"
                    placeholder="Describe the need/improvement..."
                    rows="2"
                  />
                </b-col>
                <b-col
                  cols="1"
                  class="pl-0"
                >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="h-100"
                    size="sm"
                    @click="removeNeed(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="mt-50">
                <b-col cols="6">
                  <label for="entitySelect1">Related FICs</label>
                  <b-form-select
                    id="entitySelect1"
                    v-model="need.fics"
                    :options="fics"
                    multiple
                    select-size="3"
                  />

                </b-col>
                <b-col cols="6">
                  <label for="entitySelect2">Related Objectives</label>
                  <b-form-select
                    id="entitySelect1"
                    v-model="need.objectives"
                    :options="objective_opts"
                    multiple
                    select-size="3"
                  />

                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                size="sm"
                class="ml-1 mt-25"
                @click="repeatNeed"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Add Need/Improvement</span>
              </b-button>
            </b-row>

          </b-form-group>
        </b-col>
      </b-row>

      <template v-slot:modal-footer="{ add, cancel }">
        <b-button variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="success" :disabled="loading" @click="submit">
          <span v-if="loading">
            <b-spinner small type="grow" />
            Creating...
          </span>

          <span v-else>
            Create Capability
          </span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { BButton, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'

export default {
  components: {
    TipTapEditor,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data: () => ({
    localCapability: {
      name: '',
      description: '',
      current: '',
      gaps: '',
      needs: [],
      objectives: [],
    },
    objective_opts: [],
    nextObjectId: 0,
    loading: false,
  }),
  computed: {
    ...mapState({
      selectedCapability: state => state.canvas.selectedCapability,
      objectives: state => state.canvas.objectives,
      functions: state => state.domainModel.functions,
    }),
    ...mapGetters({
      fics: 'constants/fics',
    }),
  },
  mounted() {
    this.$store.dispatch('canvas/fetchObjectives')
  },
  methods: {
    show() {
      this.$store.dispatch('domainModel/getComponents')
      this.localCapability.gaps = '<ul><li></li></ul>'
      this.objective_opts = this.objectives.map(x => ({ value: x.id, text: x.name }))
    },
    cancel() {
      this.clearForm()
      this.$bvModal.hide('create-capability-modal')
    },
    clearForm() {
      this.localCapability = {
        name: '',
        description: '',
        current: '',
        gaps: '',
        needs: [],
      }
      this.tblDataBenefits = []
      this.tblDataKPIs = []
    },
    submit() {
      this.loading = true
      console.log('CreateCapability: ', this.localCapability)
      this.$store.dispatch('canvas/createCapability', this.localCapability)
        .then(() => {
          this.loading = false
          this.clearForm()
          this.$bvModal.hide('create-capability-modal')
        })
    },

    // -- Utility -- //
    repeatNeed() {
      this.localCapability.needs.push({
        id: this.nextObjectId += 1,
        description: '',
        fics: [],
        objectives: [],
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeNeed(index) {
      this.localCapability.needs.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
  },
}
</script>

<style lang="scss">
#create-objective-modal {
  width: 85%;
  margin-left: 7.5%;
  margin-right: 7.5%;
}
</style>
