<template>
  <div>
    <h3>
      {{ label }}
      <b-button
        class="ml-1 btn-icon rounded-circle"
        size="sm"
        variant="outline-success"
        @click="$bvModal.show(`${modal}`)"
      >
        <feather-icon
          icon="PlusIcon"
        />
      </b-button>
      <slot name="additionalButtons" />
    </h3>
  </div>
</template>

<script>
export default {
  name: 'TableHeader',
  props: {
    label: {
      required: true,
      type: String,
    },
    modal: {
      required: true,
      type: String,
    },
  },
}
</script>
