<template>
  <b-modal
    id="delete-capability-modal"
    title="Delete Capability. Are you sure?"
    size="md"
    centered
    @ok="submit"
  >
    <p>
      You are about to delete
      <span class="text-primary font-weight-bolder">{{ selectedCapability.name }}</span>
      and all of its associated needs.  This cannot be undone.
    </p>
    <p class="mb-0 font-weight-bolder">
      Are you sure?
    </p>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="outline-secondary" @click="cancel()">
        Cancel
      </b-button>
      <b-button variant="danger" @click="ok()">
        Yes, delete capability
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  computed: {
    ...mapState({
      selectedCapability: state => state.canvas.selectedCapability,
    }),
  },
  methods: {
    submit(event) {
      event.preventDefault()
      this.$store
        .dispatch('canvas/deleteCapability', this.selectedCapability.id)
        .then(() => {
          this.$store.commit('canvas/CLEAR_SELECTED_CAPABILITY')
          this.$bvModal.hide('delete-capability-modal')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Capability deleted',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>
